@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Space+Grotesk');


body {
    overflow: hidden;
    -webkit-font-smoothing: auto;
    scroll-padding-top: 50px;
    
}

#container {
    background-color: rgb(203 213 225);
    box-shadow: inset 0 2px 4px 0 #94a3b8;

}

#container:focus-within {
    background-color: rgb(240 253 250);
    box-shadow: none;
    outline: solid;
    outline-width: 2px;

    outline-offset: 1px;
    outline-color: #0f766e; 
    transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    

}

.title {
    font-family: 'Space Grotesk', sans-serif;
}


.ql-container {
    font-size: 1.25rem !important ;
    line-height: 1.75rem !important;

    

    border: none !important;
    font-family: sans-serif, 'Times New Roman', Times, serif !important;
    height: 70% !important;
    font-weight: 600 !important;
    
}

.ql-formats {
    display: flex !important;
    justify-content: space-between !important;
    padding: 0.5rem;
}
.ql-toolbar {
    padding: 12px !important;
    
}
.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px !important;
}

.ql-tooltip.ql-hidden {
    display: none !important;
}

.ql-clipboard {
    display: none !important;
}


.ql-toolbar.ql-snow {
    border: 0px solid #94a3b8 !important;
    border-bottom: 2px solid #94a3b8 !important;
    padding: 0px !important;
    padding-top: 0.2rem !important;

}

.ql-snow.ql-toolbar button {
    height: 36px !important;
    width: 36px !important;
    padding: 5px !important;
    color: red !important;
    border: 0px !important;


}

.ql-container {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    font-weight: 600 !important;
}
.ql-editor {
    height: 100% !important;
    padding: 0.75rem 0.75rem !important;
    font-size: 1.125rem !important;
    line-height: 1.5rem !important;
    font-weight: 400 !important;
}


.ql-active {
    background-color: rgb(110 231 183) !important;
    border-radius: 4px !important;
    
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: rgb(19 78 74) !important;
    
}

.ql-fill {
    fill: rgb(19 78 74) !important;
}
.ql-editor.ql-blank::before {
    font-style: normal !important;
    font-weight: 400 !important;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    color: #9ea7ba !important;
    padding: 0px !important;
    margin: 0px !important;
    left: 12px !important;
}
